import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-bike-multiple',
  templateUrl: './bike-multiple.component.html',
})

export class BikeMultipleComponent implements OnInit {

  textures = [
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/Black-Bike.png',
      thumbnail:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-black-thumbnail.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/Blue-Bike.png',
      thumbnail:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-blue-thumbnail.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/Green-Bike.png',
      thumbnail:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-green-thumbnail.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/Red-Bike.png',
      thumbnail:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-red-thumbnail.png',
    },
    {
      texture:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/Yellow-Bike.png',
      thumbnail:
        'https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-yellow-thumbnail.png',
    },
  ];

  ngOnInit(): void {}

  @ViewChild('modelViewer', { static: false }) modelViewer!: ElementRef;

  // on model load
  onModelLoad(event: Event) {
    const modelViewerElement = this.modelViewer.nativeElement;
    const material = modelViewerElement.model.materials[0];
    if (!modelViewerElement.model || !modelViewerElement.model.materials) {
      console.error('Model or materials not available.');
      return;
    }

    if (!material) {
      console.error('No materials found on the model.');
      return;
    }
    const createAndApplyTexture = async (channel: any, texturePath: any) => {
      const texture = await modelViewerElement.createTexture(texturePath);
      if (texturePath === 'None') {
        material[channel]?.setTexture();
      } else {
        if (
          channel.includes('baseColorTexture') ||
          channel.includes('metallic')
        ) {
          material.pbrMetallicRoughness[channel].setTexture(texture);
        } else {
          material[channel].setTexture(texture);
        }
      }
    };

    this.createAndApplyTexture = createAndApplyTexture; // Save the function for later use
  }

  createAndApplyTexture(channel: any, texturePath: string): void {
    // Placeholder for the function that will be assigned later
  }

  // on clicking images
  onTextureChange(url: any) {
    const texturePath = url;
    this.createAndApplyTexture('baseColorTexture', texturePath);
  }

}
