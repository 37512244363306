<!-- Hero -->
<!-- <section class="hero relative py-20 md:pt-32">
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
    <img src="assets/img/gradient.jpg" alt="gradient" />
  </picture>
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
    <img src="assets/img/gradient_dark.jpg" alt="gradient dark" />
  </picture>

  <div class="container">
    <div class="py-24 text-center">
      <h1 class="text-jacarta-700 font-display mb-6 text-5xl dark:text-white lg:text-6xl xl:text-7xl">
        Welcome to Immersion Software Labs Pvt Ltd.
      </h1>
      <p class="dark:text-jacarta-200 mb-8 text-lg">Here you can</p>
      <div class="text-center">
        <a routerLink="/forgot-password"
          class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
          Forgot Password
        </a>
      </div>
    </div>
  </div>
</section> -->

<!-- end hero -->
<section class="relative py-24">
  <div class="px-6 xl:px-24">
    <div class="lg:flex lg:justify-between">
      <!-- Image -->
      <div class="lg:w-[55%]">
        <div class="relative">
          <!-- <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="mx-auto mt-8 w-[80%] rotate-[8deg]">
            <defs>
              <clipPath id="clipping" clipPathUnits="userSpaceOnUse">
                <path d="
              M 0, 100
              C 0, 17.000000000000004 17.000000000000004, 0 100, 0
              S 200, 17.000000000000004 200, 100
                  183, 200 100, 200
                  0, 183 0, 100
          " fill="#9446ED"></path>
              </clipPath>
            </defs>
            <g clip-path="url(#clipping)">
              <image href="img/about/story.jpg" width="200" height="200" clip-path="url(#clipping)"></image>
            </g>
          </svg> -->
          <img src="assets/img/company_logo.webp" alt="" class="animate-fly mx-auto mt-8 w-[80%] rotate-[8deg]" />
        </div>
      </div>

      <!-- Info -->
      <div class="py-20 lg:w-[45%] lg:pl-16">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white">
          Welcome to Immersion Software Labs Pvt Ltd.
        </h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal">
          The Next Big Immersive Experience
        </p>
        <p class="dark:text-jacarta-300 mb-10">
          One of our predominant service lines are WEB AR, completely App'less AR Experience, with hassle free and
          install free over the mobile devices featuring both licensed and custom 2D and 3D content. Our expertise just
          doesn't stop there, we have a strong research team in India working on bringing analogue world to digital in
          all possible ways for example, giving life to newspaper ads and letting the products talk for themselves while
          shopping in the supermarket.
        </p>
        <div class="flex space-x-4 sm:space-x-10">
          <div class="flex space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="fill-accent h-8 w-8 shrink-0">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 
                10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 
                2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z">
              </path>
            </svg>
            <div>
              <span class="font-display text-jacarta-700 block text-xl dark:text-white">11,2k+</span>
              <span class="dark:text-jacarta-300 text-jacarta-700">Products launched</span>
            </div>
          </div>
          <div class="flex space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="fill-accent h-8 w-8 shrink-0">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 
                10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 
                2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z">
              </path>
            </svg>
            <div>
              <span class="font-display text-jacarta-700 block text-xl dark:text-white">99,7%</span>
              <span class="dark:text-jacarta-300 text-jacarta-700">Satisfaction Rate</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--  -->
<section class="dark:bg-jacarta-800 relative py-24">
  <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
    <img src="assets/img/gradient_light.jpg" alt="gradient" class="h-full w-full" />
  </picture>
  <div class="px-6 xl:px-24">
    <h2 class="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white">
      <span class="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl" style="
          background-image: url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/26a1.png);
        "></span>
      Featured Demos
    </h2>
    <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      <!-- Car -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/car-textures">
          <figure class="relative">
            <img src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/car/car-thumbnail-red.png"
              alt="" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Car Multiple
              Textures
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Textures</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">5/5</span>
          </div>
        </div>
      </article>

      <!-- Bike -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/bike-textures">
          <figure class="relative">
            <img src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/bike/bike-green-thumbnail.png"
              alt="bike-green-thumbnail" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Bike Multiple
              Textures
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Textures</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">5/5</span>
          </div>
        </div>
      </article>
      
      <!-- Home-store -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/home-store">
          <figure class="relative">
            <img
              src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/home-store/home-store-thumbnails.png"
              alt="item 5" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Home Store
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Animation</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">2/2</span>
          </div>
        </div>
      </article>

      <!-- Office-space -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/office-space">
          <figure class="relative">
            <img
              src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/office-space/office-thumbnail-1.png"
              alt="Office Space" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Office Space
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Textures</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">2/2</span>
          </div>
        </div>
      </article>

      <!-- House Interiors -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/house-interiors">
          <figure class="relative">
            <img
              src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/office-space/office-thumbnail-1.png"
              alt="Office Space" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">House Interiors
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Textures</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">3/3</span>
          </div>
        </div>
      </article>

      <!-- Burger -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/burger">
          <figure class="relative">
            <img src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/burger/Burger.png"
              alt="Office Space" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Burger
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Animation</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">1/1</span>
          </div>
        </div>
      </article>

      <!-- Pizza -->
      <article>
        <div
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
          routerLink="/pizza">
          <figure class="relative">
            <img src="https://isl-projects-resources.s3.ap-south-1.amazonaws.com/accounts/pizza/Pizza.png"
              alt="Office Space" class="w-full rounded-[0.625rem]" loading="lazy" />

            <div class="dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2">
              <span
                class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                data-tippy-content="Favorite">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                  class="dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                  <path fill="none" d="M0 0H24V24H0z"></path>
                  <path
                    d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                  </path>
                </svg>
              </span>
              <span class="dark:text-jacarta-200 text-sm">15</span>
            </div>
          </figure>
          <div class="mt-7 flex items-center justify-between">
            <span class="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">Pizza
            </span>
          </div>
          <div class="mt-2 text-sm">
            <span class="dark:text-jacarta-200 text-jacarta-700 mr-1">Animation</span>
            <span class="dark:text-jacarta-300 text-jacarta-500">1/1</span>
          </div>
        </div>
      </article>
    </div>
  </div>
</section>