import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SpheareVideoComponent } from './spheare-video/spheare-video.component';
import { CarMultipleComponent } from './car-multiple/car-multiple.component';
import { HomeStoreComponent } from './home-store/home-store.component';
import { OfficeSpaceComponent } from './office-space/office-space.component';
import { HouseInteriorsComponent } from './house-interiors/house-interiors.component';
import { BurgerComponent } from './burger/burger.component';
import { PizzaComponent } from './pizza/pizza.component';
import { BikeMultipleComponent } from './bike-multiple/bike-multiple.component';

const routes: Routes = [
  {
    path: 'spheare-video',
    component: SpheareVideoComponent,
  },
  {
    path: 'car-textures',
    component: CarMultipleComponent,
  },
  {
    path: 'home-store',
    component: HomeStoreComponent,
  },
  {
    path: 'office-space',
    component: OfficeSpaceComponent,
  },
  {
    path: 'house-interiors',
    component: HouseInteriorsComponent,
  },
  {
    path: 'burger',
    component: BurgerComponent,
  },
  {
    path: 'pizza',
    component: PizzaComponent,
  },
  {
    path: 'bike-textures',
    component: BikeMultipleComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestingRoutingModule {}
