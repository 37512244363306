import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [],
})
export class HeaderComponent implements OnInit {
  ngOnInit(): void {}
  mobileClass: boolean = false;
  mobileMenu() {
    this.mobileClass = !this.mobileClass;
    if (this.mobileClass == true) {
      document.body.classList.add('nav-open-noscroll');
    } else {
      document.body.classList.remove('nav-open-noscroll');
    }
  }
}
