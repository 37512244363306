import { Component } from '@angular/core';

declare var AFRAME: any;
const incr = 1;
import * as THREE from 'three';

@Component({
  selector: 'app-spheare-video',
  templateUrl: './spheare-video.component.html',
  styleUrls: ['./spheare-video.component.css'],
})
export class SpheareVideoComponent {
  constructor() {}

  ngOnInit(): void {
    // this.header.hide();
    this.aframeRegister();
  }

  aframeRegister() {
    AFRAME.registerComponent('video-texture', {
      schema: {
        src: { type: 'asset', default: '' },
        map_type: { type: 'string', default: 'map' },
      },
      init: function () {
        var self = this;

        self.lightMap = new THREE.Texture();
        console.log('Light Map', self.lightMap);
        self.lightMap.image = self.data.src;
        console.log(self.lightMap);
        console.log(self.lightMap.image);
        console.log('element', this.el);
        this.el.addEventListener('model-loaded', (e: any) => {
          const mesh = this.el.getObject3D('mesh');
          console.log(mesh);
          if (mesh) {
            mesh.traverse(function (node: any) {
              // Todo: Specify material name in schema
              if (node.material && node.material.name == 'Video') {
                console.log(node.material);
                // Load Texture
                const video = document.getElementById(
                  'video'
                ) as HTMLVideoElement;
                const texture = new THREE.VideoTexture(video);
                node.material.map = texture;
                console.log(node.material.map);
                // Replace Texture
                node.material.lightMap = self.lightMap;
                node.material.lightMapIntensity = 1.5;
                node.material.needsUpdate = true;
              }
            });
          }
        });
        // function test() {
        //   document.querySelector("#video-cube")!.getObject3D("mesh").traverse(function (node: any) {
        //     if (node.material && node.material.name == "Video") {
        //       console.log(node.material);
        //       const video = document.getElementById('video');
        //       const texture = new THREE.VideoTexture(video);
        //       node.material.map = texture;
        //       // Hier muss die Video textur richtig geladen werden
        //     }
        //   });
        // })
      },
      update: function () {},
      tick: function () {},
      remove: function () {},
      pause: function () {},
      play: function () {},
    });
  }
}
