import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home-store',
  templateUrl: './home-store.component.html',
  styles: [],
})
export class HomeStoreComponent implements OnInit {
  @ViewChild('homeStore', { static: false }) homeStore!: ElementRef;
  ngOnInit(): void {}
  onModelLoad(event: Event) {
    const modelViewerElement = this.homeStore.nativeElement;
    const material = modelViewerElement.model.materials[0];
    if (!modelViewerElement.model || !modelViewerElement.model.materials) {
      console.error('Model or materials not available.');
      return;
    }

    if (!material) {
      console.error('No materials found on the model.');
      return;
    }
    // Save the function for later use
  }
}
